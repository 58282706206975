import React, { FC } from "react"
import styled from "styled-components"
import { PageWrapper } from "components/Shared/PageWrapper"
import { BackgroundImage } from "components/Shared/BackgroundImage"
import { PageTitleWithDate } from "components/Shared/PageTitle"
import { ContentWrapper } from "components/Shared/ContentWrapper"
import { IPageTemplateProps } from "./Page"
import { formatContentImgSrcToHttps, formatDate } from "utils"
import { spacing } from "styles/spacing"
import FeaturedSlides from "components/Slider/FeaturedSlides"
import mightyLogo from "../images/mighty-event-logo.png"
import SEO from "components/Seo"
import Breadcrumbs from "components/Breadcumbs"

const Wrapper = styled(PageWrapper)`
  z-index: 1;
  position: relative;
`

const WrapperFeaturedContent = styled.div`
  margin: ${spacing.md}px 0;
`

const EventTemplate: FC<IPageTemplateProps> = ({ pageContext, ...props }) => {
  return (
    <>
      <SEO
        title={pageContext.seo ? pageContext.seo.title : pageContext.title}
        description={pageContext.seo ? pageContext.seo.metaDesc : null}
        image={
          pageContext.featuredImage
            ? pageContext.featuredImage.node.sourceUrl
            : mightyLogo
        }
      />
      <BackgroundImage
        style={{
          backgroundImage: `url(${
            pageContext.featuredImage
              ? pageContext.featuredImage.node.sourceUrl
              : null
          })`,
        }}
      />
      <Wrapper className="content-wrapper">
        <PageTitleWithDate>
          {pageContext.title}
          {pageContext.date ? (
            <span>{formatDate(pageContext.date)}</span>
          ) : null}
        </PageTitleWithDate>
        <Breadcrumbs
          breadcrumbs={pageContext.seo ? pageContext.seo.breadcrumbs : null}
        />
        <ContentWrapper>
          {pageContext.content ? (
            <div
              dangerouslySetInnerHTML={{
                __html: formatContentImgSrcToHttps(pageContext.content),
              }}
            />
          ) : null}

          {/* {Boolean(
            pageContext.featuredContent &&
              pageContext.featuredContent.featuredContent
              ? pageContext.featuredContent.featuredContent.length
              : 0
          ) ? (
            <WrapperFeaturedContent>
              <FeaturedSlides
                breakpoints={{
                  768: { slidesPerView: 2, spaceBetween: spacing.lg },
                }}
                featured={pageContext.featuredContent.featuredContent}
              />
            </WrapperFeaturedContent>
          ) : null} */}
        </ContentWrapper>
      </Wrapper>
    </>
  )
}

export default EventTemplate
